.login-bg {
  background: url("bg-portal-v0.5.1.f11687a3.png") center / 100% repeat-y;
  height: 100vh;
}

.login-header {
  z-index: 1;
  background: none;
  width: 100%;
  padding: 0 4rem;
  position: fixed;
}

.logo {
  float: left;
  background-position: 0;
  background-repeat: no-repeat;
  background-size: auto 36px;
  width: 240px;
  height: 90px;
}

.black-zh {
  background-image: url("logo-black-zh@2x.f4a216fc.png");
}

.black-en {
  background-image: url("logo-black-en@2x.43fe82a1.png");
}

.black-jp {
  background-image: url("logo-black-jp@2x.2320bdd6.png");
}

.logo-en {
  float: left;
  background-image: url("logo-en.f4535865.png");
  background-position: 0;
  background-repeat: no-repeat;
  background-size: auto 100%;
  width: 300px;
  height: 60px;
}

.logo-cn {
  float: left;
  background-image: url("logo-cn.b4fbe26c.png");
  background-position: 0;
  background-repeat: no-repeat;
  background-size: auto 60px;
  width: 300px;
  height: 60px;
}

.logo-jp {
  float: left;
  background-image: url("logo-jp.71c8b0c4.png");
  background-position: 0;
  background-repeat: no-repeat;
  background-size: auto 60px;
  width: 300px;
  height: 60px;
}

.logo-white {
  float: left;
  background-image: url("logo.95021517.png");
  background-position: 0;
  background-repeat: no-repeat;
  background-size: auto 60px;
  width: 300px;
  height: 60px;
}

.login-bg .menu-right {
  background: unset;
  margin: 1rem 0;
}

.login-footer {
  text-align: center;
  background: unset;
  color: #999;
}

.login-footer a {
  color: #6483af;
  margin: 1rem 1rem 0;
  display: inline-block;
}

.login-form-container {
  flex: 1;
  height: 100vh;
}

.login-form-container .ant-tabs-nav-list {
  margin: 0 auto;
}

.login-card {
  color: #333;
  background: #fff;
  border: 0;
  border-radius: 10px;
  max-width: 450px;
  margin: 1rem;
  box-shadow: 0 0 10px #003d9724;
}

.login-card .subtitle.is-4 {
  text-align: center;
  border-bottom: 1px solid #ededed;
  margin: 0 -1rem 2rem;
  padding-bottom: 1rem;
  font-size: 24px;
}

.login-form {
  color: #fff;
}

.method-btn {
  cursor: pointer;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
  width: 34px;
  height: 34px;
  margin: 5px;
  display: inline-block;
}

.method-btn.wx {
  background-image: url("wx.3857bdad.svg");
}

.method-btn.google {
  background-image: url("google.097fbda1.svg");
}

.has-text-grey-light {
  margin: 0;
}

.has-text-grey-light a {
  color: #fff9;
}

.verify-code {
  display: inline-flex;
}

.verify-code .img {
  margin-right: 1rem;
}

/*# sourceMappingURL=Home.4184e5f3.css.map */
