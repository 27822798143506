.login-bg{
  // background: url('../../assets/images/bg-portal.svg') center no-repeat;
  background: url('../../assets/images/bg-portal-v0.5.1.png') center repeat-y;
  background-size: 100% auto;
  height: 100vh;
}
.login-header{
  position: fixed;
  z-index: 1;
  width: 100%;
  padding: 0 4rem;
  background: rgba(0,0,0,0);
}
/* 登录页面使用logo */
.logo{
  float: left;
  height: 90px;
  width: 240px;
  background-repeat: no-repeat;
  background-position: center left;
  background-size: auto 36px;
}
.black-zh{
  background-image: url('../../assets/images/logo-black-zh@2x.png');
}
.black-en{
  background-image: url('../../assets/images/logo-black-en@2x.png');
}
.black-jp{
  background-image: url('../../assets/images/logo-black-jp@2x.png');
}


/* 外部链接 使用logo */
.logo-en{
  float: left;
  height: 60px;
  width: 300px;
  background-image: url('../../assets/images/logo-en.png');
  background-repeat: no-repeat;
  background-position: center left;
  background-size: auto 100%;
}
.logo-cn{
  float: left;
  height: 60px;
  width: 300px;
  background-image: url('../../assets/images/logo-cn.png');
  background-repeat: no-repeat;
  background-position: center left;
  background-size: auto 60px;
}
.logo-jp{
  float: left;
  height: 60px;
  width: 300px;
  background-image: url('../../assets/images/logo-jp.png');
  background-repeat: no-repeat;
  background-position: center left;
  background-size: auto 60px;
}
.logo-white{
  float: left;
  height: 60px;
  width: 300px;
  background-image: url('../../assets/images/logo.png');
  background-repeat: no-repeat;
  background-position: center left;
  background-size: auto 60px;
}
.login-bg .menu-right{
  background: unset;
  margin: 1rem 0;
}
.login-footer{
  text-align: center;
  background: unset;
  color: #999;
  a {
    display: inline-block;
    color: #6483AF;
    margin: 1rem 1rem 0 1rem;
  }
}

.login-form-container{
  height: 100vh;
  flex: 1;
  .ant-tabs-nav-list {
    margin: 0 auto;
  }
}
.login-card{
  background: #fff;
  margin: 1rem;
  color: #333;
  border-radius: 10px;
  border: 0;
  max-width: 450px;
  box-shadow: 0 0 10px rgba(0, 61, 151, 0.14);
  .subtitle.is-4{
    text-align: center;
    font-size: 24px;
    border-bottom: 1px solid #EDEDED;
    margin: 0 -1rem 2rem -1rem;
    padding-bottom: 1rem;
  }
}

.login-form{
  color: #fff;
}
/* 其他登录方式 */
.method-btn {
  display: inline-block;
  width: 34px;
  height: 34px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 100% auto;
  margin: 5px;
  cursor: pointer;
}
.method-btn.wx {
  background-image: url('../../assets/images/wx.svg');
}
.method-btn.google {
  background-image: url('../../assets/images/google.svg');
}
.has-text-grey-light{
  margin: 0;
}
.has-text-grey-light a{
  color: rgba(255, 255, 255, .6);
}
/* 验证码 */
.verify-code {
  display: inline-flex;
  .img {
    margin-right: 1rem;
  }
  .val {

  }
  .msg {

  }
}